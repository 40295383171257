














































import { Vue, Component } from "vue-property-decorator";
import { Input, Button } from "element-ui";
import commonstore from "@/store/modules/common";
import authStore from "@/store/modules/auth";
import {
  IncidentRouter,
  AdminRouter,
  RootRouter,
} from "@/utils/routePathContsant";
import { eventHub } from "@/utils/eventHub";
import helper from "@/utils/helpers";
import incidentroute from "../../router/incident";
// import complainroute from "../../router/complain";
import { Action as action } from "@/store/actionType";

@Component({
  components: {
    Input,
  },
})
export default class Login extends Vue {
  userName: string = "";
  userPassword: string = "";
  isLoginActive = false;
  rootURL = action.Base;
  created() {
    //const profile = helper.getUserProfile();
    // if (profile) {
    //   this.$router.push(IncidentRouterPath.IncidentDashboard);
    // }
    eventHub.$on("error", this.errorHandler);
  }
  updated() {}
  get AdminRouter() {
    return AdminRouter;
  }
  get RootRouter() {
    return RootRouter;
  }
  async login(e: any) {
    let isValid = await this.$validator.validateAll();
    if (!isValid) {
      return;
    }
    this.isLoginActive = true;
    authStore.setLockTokenExpiredMessage(false);
    await authStore.policeLoginUser({
      username: this.userName,
      password: this.userPassword,
      source: "web",
    });
    const profile = helper.getUserProfile();

    this.isLoginActive = false;
    if (profile && profile.Role == "admin") {
      this.$router.push(AdminRouter.Root);
    } else {
      this.$router.push(IncidentRouter.IncidentDashboard);
    }
  }

  cancel() {
    this.$router.push({ name: "Home" });
  }

  enterLogin(e: any) {
    this.login(e);
  }

  errorHandler(e: any) {
    this.isLoginActive = false;
  }
}
